<template>
  <div>
    <div class="notifications-container">
      <div v-for="notification in getAllNotifications" :key="notification.id">
        <div class="notification" :class="`bg-${notification.type}`" role="alert">
          <div class="d-flex align-items-center">
            <div class="notification-body">
              {{ notification.message }}
            </div>
            <button type="button" class="btn-close btn-close-white btn-sm ms-auto me-2" @click="() => removeNotification(notification.id)" data-bs-dismiss="toast" aria-label="Close"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "AllNotifications",

  computed: {
    ...mapGetters('notifications', ['getAllNotifications'])
  },

  methods: {
    ...mapActions('notifications', ['removeNotification'])
  }

}
</script>

<style lang="scss" scoped>
.notifications-container {
  display: block;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 99999;
  width: 100%;

  @media (min-width: 991px) {
    margin-bottom: 5px;
    margin-right: 20px;
    width: auto;
    max-width: 500px;
  }
}

.notification {
  min-width: 300px;
  padding: .9rem 1rem;
  border-radius: 0;
  z-index: 9999;

  @media (min-width: 991px) {
    bottom: 20px;
    right: 20px;
    left: auto;
    min-width: 300px;
    padding: .6rem 1rem;
    border-radius: 3px;
    margin-top: 4px;
  }

  .notification-body {
    color: white;
    margin-right: 30px;
  }
}
</style>
