
import Vue from 'vue';
import VerticalNavMenu from "@/layouts/components/VerticalNavMenu.vue";
import {mapActions, mapGetters} from "vuex";
import MobileOpenSidebar from "@/components/articles/articles-header/all-icons/MobileOpenSidebar.vue";
import AllNotifications from "@/components/all-notifications/AllNotifications.vue";


export default Vue.extend({
  name: "Dashboard",
  components: {AllNotifications, MobileOpenSidebar, VerticalNavMenu},

  computed: {
    ...mapGetters('dashboard', ['getDashboardStyle'])
  },

  methods: {
    ...mapActions('notifications', ['clearNotification']),
    ...mapActions('application', ['setIsMobile', 'setWindowHeight']),

    // Checks the window resize event
    logResize() {
      this.setIsMobile(window.innerWidth <= 991);
      this.setWindowHeight(window.innerHeight);
    },

    // Used for checking window size on a fixed device
    checkWindowSizeOnLoad() {
      this.setIsMobile(window.innerWidth <= 991);
      this.setWindowHeight(window.innerHeight);
    }

  },

  created() {
    this.checkWindowSizeOnLoad();
    window.addEventListener('resize', this.logResize);
  },

  destroyed() {
    window.removeEventListener('resize', this.logResize);
  }
});
