
import { resetUrlParams } from "@/helpers/urlParamsHelper";
import Logo from "@/layouts/components/Logo.vue";
import NavItemSidebar from "@/layouts/components/NavItemSidebar.vue";
import router from "@/router";
import { defaultSearchOptions } from "@/store/services/articleService";
import { LayoutStyle } from "@/types/dashboard/LayoutStyle";
import Vue from 'vue';
import {
  CheckCircleIcon,
  CircleIcon,
  FileTextIcon,
  FolderIcon,
  HelpCircleIcon,
  LogOutIcon,
  UserIcon
} from 'vue-feather-icons';
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "VerticalNavMenu",
  components: {
    NavItemSidebar,
    Logo,
    UserIcon,
    LogOutIcon,
    HelpCircleIcon,
    CircleIcon,
    CheckCircleIcon,
    FolderIcon,
    FileTextIcon
  },

  computed: {
    ...mapGetters('dashboard', ['getDashboardStyle']),
    ...mapGetters('application', ['getIsMobile']),

    // Determine the style to display for vertical navbar
    determineDashboardStyle() {
      if (this.getDashboardStyle.pinned) return LayoutStyle.MAX;
      if (this.getDashboardStyle.type === 'max') return LayoutStyle.MAX;
      else return LayoutStyle.MIN;
    },

    determineDashboardClass() {
      if (this.getIsMobile) {
        return this.getDashboardStyle.pinned ? 'dashboard-sidebar--overlay' : 'd-none';
      } else {
        if (this.getDashboardStyle.pinned) return 'dashboard-sidebar--max';
        if (this.getDashboardStyle.type === LayoutStyle.MAX) return 'dashboard-sidebar--max';
        else return 'dashboard-sidebar--min';
      }
    },

  },

  methods: {
    resetSearch(){
      const parsedString = JSON.stringify(defaultSearchOptions);
      const clone = JSON.parse(parsedString);
      this.$store.dispatch('search/resetSearchFilters', clone);

      // Resets the URL to the default route
      resetUrlParams();

      this.$store.dispatch('filters/resetAllSelectedFiltersSidebar');
      this.$store.dispatch('articles/getArticles');
      this.$store.dispatch('search/setSavedSearchName', '');
      router.push('/dashboard/articles')
    },
    setPinnedSidebar() {
      const pinSidebar = !this.getDashboardStyle.pinned;
      this.$store.dispatch('dashboard/setDashboardPinned', pinSidebar);
    },

    linkIsActive(routeName: string) {
      return this.$route.name === routeName;
    },

    setDashboardMin() {
      this.$store.dispatch('dashboard/setDashboardStyleClass', LayoutStyle.MIN);
    },

    setDashboardMax() {
      this.$store.dispatch('dashboard/setDashboardStyleClass', LayoutStyle.MAX);
    }

  }
});
