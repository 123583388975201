<template>
  <div class="mobile-sidebar-open" v-if="getIsMobile" @click="openMobileSidebar">
    <div class="icon-styling"
         v-show="getIsMobile"
         @mouseover="showingPopover = true"
         @mouseleave="showingPopover = false"
         data-bs-toggle="tooltip"
         data-bs-placement="top" title="Open Mobile Sidebar">
      <arrow-right-icon />

      <custom-pop-over
          :left="-20"
          v-if="showingPopover">
        Open Mobile Sidebar
      </custom-pop-over>
    </div>
  </div>
</template>

<script>
import CustomPopOver from "@/components/CustomPopOver";
import {ArrowRightIcon} from 'vue-feather-icons';

import {mapActions, mapGetters} from "vuex";
export default {
  name: "MobileOpenSidebar",
  components: {CustomPopOver, ArrowRightIcon},

  computed: {
    ...mapGetters('application', ['getIsMobile'])
  },

  data() {
    return {
      showingPopover: false
    }
  },

  methods: {
    ...mapActions('dashboard', ['setDashboardPinned']),

    openMobileSidebar() {
      this.setDashboardPinned(true);
    }

  }

}
</script>

<style lang="scss" scoped>
.mobile-sidebar-open {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2;
  background: white;
  padding: .5rem 1rem;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
}
</style>
